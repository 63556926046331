import { customFunctions } from "@/config/customFunction";
import { itemType } from "@/constants/items";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { GradesAutoComplete } from "@/features/ui/autoComplete/grade/grades-autoComplete";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import { Checkbox, Flex, NumberInput, Select, Text, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ItemsGetRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface ItemsFormProps {
  formData: UseFormReturnType<Partial<ItemsGetRequest>>;
}

export const ItemsForm = ({ formData }: ItemsFormProps) => {
  const { t } = useTranslation();
  const lnItemType = itemType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  useEffect(() => {
    const { thickness, width, pitch, specificGravity, cvt } = formData.values;
    if (thickness && width && pitch && specificGravity && cvt) {
      const grossWeight =
        (parseFloat(thickness) *
          parseFloat(width) *
          parseFloat(pitch) *
          parseFloat(specificGravity)) /
        (1000000 * parseFloat(cvt));
      formData.setFieldValue("grossWeight", grossWeight.toFixed(3)); // 소수점 3자리로 고정
    }
  }, [
    formData.values.thickness,
    formData.values.width,
    formData.values.pitch,
    formData.values.specificGravity,
    formData.values.cvt,
  ]);

  const handleFocus = () => {
    formData.setFieldError("grossWeight", "G/W 값은 자동으로 계산되며 수정할 수 없습니다.");
  };

  const handleBlur = () => {
    formData.clearFieldError("grossWeight");
  };

  return (
    <Flex direction="column" gap="sm" p="md" w="100%">
      <Flex gap="xl" align="center">
        <Flex gap="md" align="center">
          <Text fw={700} fz="lg" color="red" w="1rem">
            *
          </Text>
          <Text fw={700} fz="lg" color="red" w="10rem">
            {t("item.name")}
          </Text>
        </Flex>
        <TextInput
          placeholder={t("placeholder.input", { item: t("item.name") })}
          size="md"
          w="20rem"
          {...formData.getInputProps("name")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Flex gap="md" align="center">
          <Text fw={700} fz="lg" color="red" w="1rem">
            *
          </Text>
          <Text fw={700} fz="lg" color="red" w="10rem">
            {t("item.type")}
          </Text>
        </Flex>
        <Select
          placeholder={t("placeholder.select", { item: t("item.type") })}
          data={lnItemType}
          size="md"
          w="20rem"
          {...formData.getInputProps("itemType")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Flex gap="md" align="center">
          <Text fw={700} fz="lg" color="red" w="1rem">
            *
          </Text>
          <Text fw={700} fz="lg" color="red" w="10rem">
            {t("item.code")}
          </Text>
        </Flex>
        <TextInput
          placeholder={t("placeholder.input", { item: t("item.code") })}
          size="md"
          w="20rem"
          {...formData.getInputProps("code")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.category")}
        </Text>
        <ItemCategoryAutoComplete
          placeholder={t("placeholder.select", { item: t("item.category") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("itemCategoryName")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.abstractItemCode")}
        </Text>
        <AbstractItemAutoComplete
          placeholder={t("placeholder.select", { item: t("item.abstractItemCode") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("abstractItemCode")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.spec")}
        </Text>
        <TextInput
          placeholder={t("placeholder.input", { item: t("item.spec") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("spec")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.height")}
        </Text>
        <NumberInput
          min={1}
          step={0.01}
          precision={2}
          placeholder={t("placeholder.input", { item: t("item.height") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("height")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.depth")}
        </Text>
        <NumberInput
          min={1}
          step={0.01}
          precision={2}
          placeholder={t("placeholder.input", { item: t("item.depth") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("depth")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          재질
        </Text>
        <TextInput
          placeholder="재질을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("texture")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          두께
        </Text>
        <NumberInput
          min={1}
          step={1}
          placeholder="두께을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("thickness")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          재질
        </Text>
        <TextInput
          placeholder="재질을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("texture")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          두께
        </Text>
        <NumberInput
          min={1}
          step={1}
          placeholder="두께을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("thickness")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.width")}
        </Text>
        <NumberInput
          min={1}
          step={0.01}
          precision={2}
          placeholder={t("placeholder.input", { item: t("item.width") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("width")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          피치
        </Text>
        <NumberInput
          min={1}
          step={0.01}
          precision={2}
          placeholder="피치를 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("pitch")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          비중
        </Text>
        <NumberInput
          min={1}
          step={0.01}
          precision={2}
          placeholder="비중을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("specificGravity")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          CVT
        </Text>
        <NumberInput
          min={1}
          step={1}
          placeholder="CVT을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("cvt")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          G/W
        </Text>
        <TextInput
          readOnly
          size="sm"
          w="20rem"
          value={formData.values.grossWeight}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={formData.errors.grossWeight}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.standardUph")}
        </Text>
        <NumberInput
          placeholder={t("placeholder.input", { item: t("item.standardUph") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("standardUph")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.safetyStock")}
        </Text>
        <TextInput
          placeholder={t("placeholder.input", { item: t("item.safetyStock") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("safetyStock", {
            withError: true, // 에러 표시 활성화
          })}
          error={
            formData.values.safetyStock && !/^\d+$/.test(formData.values.safetyStock)
              ? t("message.numberOnly")
              : undefined
          }
        />
      </Flex>

      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.unit")}
        </Text>
        <TextInput
          placeholder={t("placeholder.input", { item: t("item.unit") })}
          size="sm"
          w="20rem"
          {...formData.getInputProps("unitText")}
        />
      </Flex>
      {customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING && (
        <Flex gap="xl" align="center">
          <Text fw={700} c="dimmed" w="12rem">
            {t("item.grade")}
          </Text>
          <GradesAutoComplete
            placeholder={t("placeholder.select", { item: t("item.grade") })}
            size="sm"
            w="20rem"
            {...formData.getInputProps("gradeCode")}
          />
        </Flex>
      )}
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.lot Management")}
        </Text>
        <Flex justify="space-between" align="center" gap="xl">
          <Checkbox label={t("lot.name")} {...formData.getInputProps("usesLotName")} />
          <Checkbox label={t("lot.expiration")} {...formData.getInputProps("usesLotExpiration")} />
        </Flex>
      </Flex>
    </Flex>
  );
};
