import { customFunctions } from "@/config/customFunction";
import { itemTypeColor } from "@/constants/items";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { theme } from "@/styles/theme";
import { Badge, Checkbox, Flex, Space, Text, Title } from "@mantine/core";
import { ItemsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

export const ItemView = (params: { itemData: Partial<ItemsGet200ResponseRowsInner> }) => {
  const {
    name,
    code,
    itemType,
    itemCategoryName,
    abstractItemCode,
    usesLotName,
    usesLotExpiration,
    spec,
    height,
    standardUph,
    depth,
    width,
    texture,
    unitText,
    gradeCode,
    thickness,
    pitch,
    specificGravity,
    cvt,
    grossWeight,
    safetyStock,
  } = params.itemData;

  const { t } = useTranslation();

  const { openModal } = useModal();

  //name, code, itemType, itemCategoryId, abstractItemId
  // TODO : 추후 props로 받는값으로 바꿔주기
  return (
    <Flex w="100%" p="md" bg={theme.colors?.gray?.[0]} direction="column" gap="0.5rem">
      <Title>{t("common.information", { item: `${t("item.item")}` })}</Title>
      <Space h="md" />
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.name")}
        </Text>
        <Text fz="sm" w="20rem">
          {name}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.code")}
        </Text>
        <Text fz="sm" w="20rem">
          {code}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.type")}
        </Text>
        <Badge color={itemTypeColor[itemType!]}>{t(itemType!)}</Badge>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("category.category")}
        </Text>
        <DetailLink
          w="20rem"
          justify="flex-start"
          onClick={() =>
            openModal(<ItemCategoryDetailForm ItemCategoryName={itemCategoryName} />, null, "")
          }
        >
          {itemCategoryName}
        </DetailLink>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("abstractItem.code")}
        </Text>
        <DetailLink
          w="20rem"
          justify="flex-start"
          onClick={() =>
            openModal(<AbstractItemDetailForm AbstractItemCode={abstractItemCode} />, null, "")
          }
        >
          {abstractItemCode}
        </DetailLink>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.spec")}
        </Text>
        <Text fz="sm" w="20rem">
          {spec}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.height")}
        </Text>
        <Text fz="sm" w="20rem">
          {height}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.depth")}
        </Text>
        <Text fz="sm" w="20rem">
          {depth}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.texture")}
        </Text>
        <Text fz="sm" w="20rem">
          {texture}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.thickness")}
        </Text>
        <Text fz="sm" w="20rem">
          {thickness}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.width")}
        </Text>
        <Text fz="sm" w="20rem">
          {width}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.pitch")}
        </Text>
        <Text fz="sm" w="20rem">
          {pitch}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.specificGravity")}
        </Text>
        <Text fz="sm" w="20rem">
          {specificGravity}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.cvt")}
        </Text>
        <Text fz="sm" w="20rem">
          {cvt}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.grossWeight")}
        </Text>
        <Text fz="sm" w="20rem">
          {grossWeight}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.standardUph")}
        </Text>
        <Text fz="sm" w="20rem">
          {standardUph}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.safetyStock")}
        </Text>
        <Text fz="sm" w="20rem">
          {safetyStock}
        </Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.unit")}
        </Text>
        <Text fz="sm" w="20rem">
          {unitText}
        </Text>
      </Flex>
      {customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING && (
        <Flex>
          <Text fw={700} c="dimmed" w="12rem">
            {t("item.grade")}
          </Text>
          <Text fz="sm" w="20rem">
            {gradeCode}
          </Text>
        </Flex>
      )}
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          {t("item.lot Management")}
        </Text>
        <Flex justify="space-between" align="center" gap="xl">
          <Checkbox label={t("lot.name")} defaultChecked={usesLotName} disabled />
          <Checkbox label={t("lot.expiration")} defaultChecked={usesLotExpiration} disabled />
        </Flex>
      </Flex>
    </Flex>
  );
};
